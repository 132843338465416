//@author: devin
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class S25BasicUtil {
    constructor() {}

    public static forEach(obj: any, callback: any): any {
        if (obj && callback && typeof callback === "function") {
            if (obj.constructor === Array) {
                obj.forEach(callback);
            } else if (typeof obj === "object") {
                Object.keys(obj).forEach(function (key: any) {
                    callback(obj[key], key);
                });
            }
        }
    }

    public static isUndefined(obj: any): boolean {
        return typeof obj === "undefined" || obj === null || Number.isNaN(obj);
    }

    public static isDefined(obj: any): boolean {
        return !this.isUndefined(obj);
    }

    public static toBool(bool: any): boolean {
        return !(
            bool === "false" ||
            bool === "0" ||
            bool === 0 ||
            bool === false ||
            bool === "" ||
            bool === undefined ||
            bool === null
        );
    }
}
